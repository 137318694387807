import React from 'react'

type iconType =
  | 'camera'
  | 'course_text'
  | 'course_video'
  | 'course_past_exam_analysis'
  | 'course_video_timeline'
  | 'course_quiz'
  | 'arrow_left'
  | 'arrow_right'
  | 'arrow_down'
  | 'x'
  | 'nodata'
  | 'closeicon'
  | 'seemore'

type SAPP_ICONS_TYPE = {
  [key in iconType]: React.ReactNode
}

type Props = {
  icon: keyof SAPP_ICONS_TYPE
  className?: string
}

const SappIcon = ({ icon, className }: Props) => {
  const SAPP_ICONS: SAPP_ICONS_TYPE = {
    camera: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
        className={className}
      >
        <g fill="#A1A1A1" clipPath="url(#a)">
          <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6ZM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2Z" />
          <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5Zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    ),
    course_text: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
        className={className}
      >
        <rect
          width={15}
          height={15}
          x={0.5}
          y={0.5}
          stroke="#A1A1A1"
          rx={1.5}
        />
        <path
          fill="#A1A1A1"
          d="M3.333 4.2c0-.11.09-.2.2-.2h8.934c.11 0 .2.09.2.2v.6a.2.2 0 0 1-.2.2H3.533a.2.2 0 0 1-.2-.2v-.6ZM3.333 6.533c0-.11.09-.2.2-.2h8.934c.11 0 .2.09.2.2v.6a.2.2 0 0 1-.2.2H3.533a.2.2 0 0 1-.2-.2v-.6ZM3.333 8.867c0-.11.09-.2.2-.2h8.934c.11 0 .2.09.2.2v.6a.2.2 0 0 1-.2.2H3.533a.2.2 0 0 1-.2-.2v-.6ZM3.333 11.2c0-.11.09-.2.2-.2H9.8c.11 0 .2.09.2.2v.6a.2.2 0 0 1-.2.2H3.533a.2.2 0 0 1-.2-.2v-.6Z"
        />
      </svg>
    ),
    course_video: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
        className={className}
      >
        <rect
          width={15}
          height={15}
          x={0.5}
          y={0.5}
          stroke="#A1A1A1"
          rx={1.5}
        />
        <path
          stroke="#A1A1A1"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 10.403V5.466a.469.469 0 0 1 .709-.4l4.228 2.457a.468.468 0 0 1 0 .812L6.71 10.803a.469.469 0 0 1-.709-.4Z"
        />
      </svg>
    ),
    course_quiz: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
      >
        <path
          fill="#A1A1A1"
          stroke="#A1A1A1"
          strokeWidth={0.1}
          d="M7.202 9.69c.005-.49.062-.884.173-1.18a1.79 1.79 0 0 1 .473-.72 3.74 3.74 0 0 1 .723-.5c.164-.09.31-.195.44-.317.13-.123.23-.264.304-.424.076-.16.115-.335.115-.53 0-.24-.063-.447-.187-.621a1.225 1.225 0 0 0-.503-.408 1.684 1.684 0 0 0-.706-.145c-.226 0-.444.043-.653.127-.207.083-.38.214-.52.393a1.12 1.12 0 0 0-.2.437c-.04.17-.183.313-.368.313h-.477c-.198 0-.366-.169-.329-.373.057-.308.172-.582.347-.82A2.25 2.25 0 0 1 6.76 4.2a3.175 3.175 0 0 1 1.274-.249c.505 0 .946.09 1.323.273.378.182.672.432.879.752.21.32.314.683.314 1.09 0 .286-.05.547-.148.78a1.9 1.9 0 0 1-.42.624M7.203 9.69l2.745-2.256M7.202 9.69c0 .053.043.096.096.096h.928a.096.096 0 0 0 .096-.096h-.05m-1.07 0h1.07m1.71-2.22-.035-.036m.036.035-.036-.035m.036.035c-.18.182-.396.343-.65.483m.614-.518a2.756 2.756 0 0 1-.637.474m.024.044-.024-.044m.024.044a2.55 2.55 0 0 0-.588.435c-.145.147-.25.322-.316.527a2.738 2.738 0 0 0-.108.777h-.05m1.062-1.74v.001l-.024-.044m0 0L8.272 9.69m-.07 2.262a.808.808 0 0 0 .295-.268.681.681 0 0 0-.127-.895.83.83 0 0 0-.574-.216.83.83 0 0 0-.573.216.681.681 0 0 0-.242.523c0 .204.082.379.242.523a.83.83 0 0 0 .573.215c.15 0 .285-.032.406-.098Z"
        />
        <rect
          width={15}
          height={15}
          x={0.5}
          y={0.5}
          stroke="#A1A1A1"
          rx={1.5}
        />
      </svg>
    ),
    course_past_exam_analysis: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 24 24"
        fill="none"
      >
        <rect width="24" height="24" rx="3" fill="#F1F1F2" />
        <rect
          x="5.25"
          y="19"
          width="11"
          height="1.5"
          rx="0.3"
          transform="rotate(-90 5.25 19)"
          fill="#404041"
        />
        <rect
          x="11.25"
          y="19"
          width="14"
          height="1.5"
          rx="0.3"
          transform="rotate(-90 11.25 19)"
          fill="#404041"
        />
        <rect
          x="17.25"
          y="19"
          width="9"
          height="1.5"
          rx="0.3"
          transform="rotate(-90 17.25 19)"
          fill="#404041"
        />
      </svg>
    ),
    course_video_timeline: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        fill="none"
      >
        <path
          className={className}
          d="M17.438 4.219H.563v1.687h16.875V4.22ZM14.625 8.156H3.375v1.688h11.25V8.156ZM11.25 12.094h-4.5v1.687h4.5v-1.687Z"
        />
      </svg>
    ),
    arrow_left: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
        className={className}
      >
        <path
          fill="#404041"
          d="M11.313 3.411V2.203a.125.125 0 0 0-.202-.098L4.067 7.606a.498.498 0 0 0 0 .786l7.044 5.502a.124.124 0 0 0 .201-.099v-1.207a.253.253 0 0 0-.095-.197L5.592 8l5.625-4.392a.253.253 0 0 0 .095-.197Z"
        />
      </svg>
    ),
    arrow_right: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
        className={className}
      >
        <path
          fill="#404041"
          d="M4.688 12.59v1.207c0 .105.12.162.201.098l7.044-5.501a.497.497 0 0 0 0-.786L4.889 2.106a.124.124 0 0 0-.201.099v1.207c0 .077.035.15.095.197L10.408 8l-5.625 4.392a.253.253 0 0 0-.095.197Z"
        />
      </svg>
    ),
    arrow_down: (
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path d="M4 9L9.5 14L15 9H4Z" />
      </svg>
    ),
    x: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className={className + ' fill-gray-1'}
      >
        <path d="M13.41 12L17 8.44003C17.191 8.24642 17.2972 7.98489 17.2953 7.71296C17.2934 7.44103 17.1836 7.18099 16.99 6.99003C16.7964 6.79908 16.5349 6.69285 16.2629 6.69473C15.991 6.6966 15.731 6.80642 15.54 7.00003L12 10.59L8.46 7.07003C8.27263 6.88378 8.01918 6.77924 7.755 6.77924C7.49081 6.77924 7.23736 6.88378 7.05 7.07003C6.95627 7.16299 6.88187 7.2736 6.8311 7.39546C6.78034 7.51731 6.7542 7.64802 6.7542 7.78003C6.7542 7.91204 6.78034 8.04275 6.8311 8.16461C6.88187 8.28647 6.95627 8.39707 7.05 8.49003L10.59 12L7 15.56C6.80904 15.7536 6.70282 16.0152 6.70469 16.2871C6.70657 16.559 6.81639 16.8191 7.01 17.01C7.2036 17.201 7.46514 17.3072 7.73707 17.3053C8.00899 17.3035 8.26904 17.1936 8.46 17L12 13.41L15.54 16.93C15.7274 17.1163 15.9808 17.2208 16.245 17.2208C16.5092 17.2208 16.7626 17.1163 16.95 16.93C17.0437 16.8371 17.1181 16.7265 17.1689 16.6046C17.2197 16.4827 17.2458 16.352 17.2458 16.22C17.2458 16.088 17.2197 15.9573 17.1689 15.8355C17.1181 15.7136 17.0437 15.603 16.95 15.51L13.41 12Z" />
      </svg>
    ),
    nodata: (
      <svg
        width="70"
        height="70"
        viewBox="0 0 70 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-fill--available"
      >
        <g clipPath="url(#clip0_9347_5425)">
          <path
            d="M12.9347 56.9559H57.0653C64.1791 56.9559 70 51.1634 70 44.0847C70 37.0081 64.1791 31.2156 57.0653 31.2156H56.0284C54.9981 26.8778 51.0891 23.6272 46.4122 23.6272C45.2769 23.6272 44.1262 23.8766 43.1747 24.1959C40.8887 17.7056 34.6938 13.0441 27.3919 13.0441C18.1475 13.0441 10.6509 20.5013 10.6509 29.7019C10.6509 30.2816 10.6816 30.8547 10.7384 31.4169C4.66375 32.4625 0 37.7519 0 44.0847C0 51.1656 5.82094 56.9559 12.9347 56.9559ZM10.9266 32.4931L11.9328 32.3203L11.8278 31.3031C11.7731 30.7694 11.7447 30.2291 11.7447 29.6997C11.7447 21.1181 18.7644 14.1356 27.3919 14.1356C34.02 14.1356 39.9481 18.3225 42.1422 24.5569L42.5009 25.5719L43.5225 25.2306C44.2203 24.9966 45.29 24.7188 46.4144 24.7188C50.505 24.7188 54.0203 27.4947 54.9653 31.4672L55.1644 32.3072H56.0284H57.0653C63.595 32.3072 68.9062 37.59 68.9062 44.0825C68.9062 50.5772 63.595 55.86 57.0653 55.86H12.9347C6.405 55.86 1.09375 50.5772 1.09375 44.0825C1.09375 38.3491 5.22812 33.4731 10.9266 32.4931Z"
            fill="#A1A1A1"
          />
          <path
            d="M20.1532 47.3725L28.5598 38.8282L36.9642 47.3725L37.7473 46.6047L29.3276 38.045L37.7473 29.4854L36.9642 28.7197L28.5598 37.2641L20.1532 28.7197L19.3701 29.4854L27.7898 38.045L19.3701 46.6047L20.1532 47.3725Z"
            fill="#A1A1A1"
          />
        </g>
        <defs>
          <clipPath id="clip0_9347_5425">
            <rect width="70" height="70" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    closeicon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.21967 6.21967C6.51256 5.92678 6.98744 5.92678 7.28033 6.21967L17.7803 16.7197C18.0732 17.0126 18.0732 17.4874 17.7803 17.7803C17.4874 18.0732 17.0126 18.0732 16.7197 17.7803L6.21967 7.28033C5.92678 6.98744 5.92678 6.51256 6.21967 6.21967Z"
          fill="#21272A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.7803 6.21967C18.0732 6.51256 18.0732 6.98744 17.7803 7.28033L7.28033 17.7803C6.98744 18.0732 6.51256 18.0732 6.21967 17.7803C5.92678 17.4874 5.92678 17.0126 6.21967 16.7197L16.7197 6.21967C17.0126 5.92678 17.4874 5.92678 17.7803 6.21967Z"
          fill="#21272A"
        />
      </svg>
    ),
    seemore: (
      <svg
        className="ms-2.5 group-hover:fill-primary"
        width="12"
        height="7"
        viewBox="0 0 12 7"
        fill="#3964EA" // Original color
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.78317 4.16666H0.666504V2.83333H8.78317L6.88319 0.933387L7.83319 5.34058e-05L11.3332 3.5L7.83311 7.00005L6.88311 6.06672L8.78317 4.16666Z"
          className="transition-all duration-300 ease-in-out group-hover:fill-primary" // Added hover class for fill
        />
      </svg>
    ),
  }

  return <div>{SAPP_ICONS[icon]}</div>
}

export default SappIcon
